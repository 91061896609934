import axios from "axios";
import { createContext, useState } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";

export const AxiosContext = createContext();

const AxiosProvider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(null);
  const { setItem, getItem, removeItem } = useLocalStorage();
  const navigate = useNavigate();
  const client = (() => {
    return axios.create({
      baseURL: "https://asim.qa.arsat.com.ar",
    });
  })();

  const refreshAccessToken = async () => {
    // console.log("Renovando token");
    try {
      let oldToken = await getItem("jwt");
      const response = await client.post("/api/auth/refresh_token", oldToken);
      // console.log({ responseOutIf: response });
      if (response) {
        // console.log({ responseIf: response });
        setItem("jwt", response);
      } else {
        removeItem("jwt");
        navigate("/login");
      }
      return response;
    } catch (err) {
      console.error({ errorRenovandoToken: err });
      throw err;
    }
  };

  client.interceptors.request.use(async (config) => {
    // Modify the request config
    let jwt = await getItem("jwt");
    config.headers.Authorization = `Bearer ${jwt}`;
    return config;
  });

  client.interceptors.response.use(
    (response) => {
      // console.log({ responseInt: response });
      return response?.data ? response?.data : response;
    },
    (error) => {
      const data = error?.response?.data;
      const originalRequest = error.config;
      if (
        error?.response?.status === 401 &&
        data?.detail === "Sesión expirada"
      ) {
        console.log({ detail: "session-expired" });
        setIsAuth(false);
        removeItem("jwt");
        navigate("/session_expired");
      }
      if (
        error?.response?.status === 401 &&
        data?.detail === "Token invalido"
      ) {
        console.log({ detail: "invalid-token" });
        setIsAuth(false);
        removeItem("jwt");
        navigate("/session_expired");
      }
      if (error?.response?.status === 401 && data?.detail === "token-expired") {
        return refreshAccessToken().then((newJwt) => {
          originalRequest.headers.Authorization = `Bearer ${newJwt}`;
          return client(originalRequest);
        });
      }
      if (!data) {
        return Promise.reject({
          response: {
            data: "Hubo un error en el servidor, comuníquese con el equipo de desarrollo",
          },
        });
      } else {
        return Promise.reject({
          ...error,
          response: { ...error?.response, data: data.detail },
        });
      }
    }
  );

  const getUsers = () => {
    return client?.get("/api/users/list_users");
  };

  const acceptUser = (acceptPayload) => {
    return client?.post("/api/users/accept_account_request", acceptPayload);
  };

  const blockUser = (blockPayload) => {
    return client?.post("/api/users/block_account", blockPayload);
  };

  const getOrg = () => {
    return client?.get("/api/users/get_org");
  };

  const getUserKeys = (payload) => {
    return client?.post("/api/users/get_keys", payload);
  };

  return (
    <AxiosContext.Provider
      value={{
        client,
        getUsers,
        acceptUser,
        blockUser,
        getOrg,
        getUserKeys,
        isAuth,
        setIsAuth,
      }}
    >
      {client && children}
    </AxiosContext.Provider>
  );
};
export default AxiosProvider;
